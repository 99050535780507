import React from "react"
import { Container, Row } from "react-bootstrap"
import { ProductBoard } from "./ProductBoard"
import FdfmLogo from "../../assets/images/fdfm.png"
import OdsLogo from "../../assets/images/ods.jpeg"
import TdccLogo from "../../assets/images/tdcc.png"
import EtdLogo from "../../assets/images/etd.png"
import NlcLogo from "../../assets/images/nlc.png"
import SFLS from "../../assets/images/sfls.png"
import SFFF from "../../assets/images/sfff.png"

export default function Products() {
  return (
    <>
      <section className="section-white">
        <Container className="products_background">
          <Row>
            <h1 className="products_h1 py-2 mt-3">
              Learn Content Creation & Business Today
            </h1>
          </Row>
        </Container>
        <Container className="products_background">
          <Row className="d-flex justify-content-center">
            <ProductBoard
              logo={FdfmLogo}
              description="Learn content creation in just 14 days."
              productName="14 Day Filmmaker"
              pitch="program is for anyone who wants to produce cinematic & emotional videos without wasting months trying to figure it all out on your own."
              ctaLink="/14-day-filmmaker"
              borderColor="fdfm"
            />
            <ProductBoard
              logo={TdccLogo}
              description="Learn the most capital, energy and FUN process for building a 7 figure online course."
              productName="30 Day Course Creator"
              pitch="program is for anyone that wants a proven step-by-step process for creating, marketing & scaling 5, 6 or 7 figure online courses in just 30 days."
              buttonText="Watch Free Training"
              ctaLink="https://www.contentcreator.com/30-day-course-creator-30%25-off"
              borderColor="tdcc"
            />

            <ProductBoard
              logo={OdsLogo}
              description="Write the ultimate script for your offer in just 1 day."
              productName="1 Day Script"
              pitch="program is for anyone who wants to script write & storyboard profitable videos for their business in the shortest timeframe possible."
              ctaLink="https://www.contentcreator.com/1-day-script"
              borderColor="ods"
            />

            {/* <ProductBoard
              logo={DavinciLogo}
              description="Learn how to edit in DaVinci Resolve in just 1 day."
              productName="1 Day DaVinci Resolve Pro Editing Workflow"
              pitch="program is for anyone who wants to learn how to edit cinematic videos using DaVinci Resolve in just 1 day."
              useLink="false"
              ctaLink="/"
              borderColor="black"
            /> */}
            <ProductBoard
              logo={EtdLogo}
              description="Learn the most efficient sales system for converting prospects into high paying clients in the shortest timeframe possible."
              productName="Earn The Deal"
              pitch="sales mastery program is the ultimate sales training for creative entrepreneurs with over $27,000,000 in tracked sales in the past 2 years. "
              buttonText="Watch Free Training"
              ctaLink="https://www.contentcreator.com/earn-the-deal"
              borderColor="etd"
            />
            <ProductBoard
              logo={SFLS}
              description="How To Earn 6 Figures Adding Live Streaming Services To Your Business"
              productName="Six Figure Live Streamer"
              pitch="program gives you an in-depth look at the technical knowledge for offering live streaming services and most importantly, the business systems, tools and strategies you need to accelerate your growth to 6 figures in the fastest timeframe possible."
              buttonText="Learn More About Six Figure Livestreamer"
              ctaLink="https://www.contentcreator.com/6-figure-live-streamer"
              borderColor="sfls"
              fontSize="17px"
            />
            <ProductBoard
              logo={SFFF}
              description="How To Go From ZERO to Fulltime Fitness Filmmaker In Just 90 Days"
              productName="6 Figure Fitness Filmmaker"
              pitch="program gives you an in-depth look at the technical knowledge for offering content creation services and most importantly, the business systems, tools and strategies you need to accelerate your growth to 6 figures as a health & fitness filmmaker in the fastest timeframe possible."
              buttonText="Learn More About Six Figure Fitness Filmmaker"
              ctaLink="https://www.contentcreator.com/6-figure-fitness-filmmaker"
              borderColor="sfff"
              fontSize="17px"
            />
            <ProductBoard
              logo={NlcLogo}
              description="Add a minimum of $10,000 in monthly recurring revenue (MRR) to your video agency in just 90 days."
              productName="Next Level Creators"
              pitch="program is a customizable mentorship program designed to help you install proven business systems into your business and scale to multiple 6 then 7 figures in the fastest timeframe possible."
              buttonText="Watch Free Training"
              ctaLink="/training"
              borderColor="etd"
              fontSize="18px"
            />
          </Row>
        </Container>
      </section>
    </>
  )
}
