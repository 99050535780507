import * as React from "react"
import { Home } from "../components/main/Home"

import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <Seo title="Home" />
      <Home />
    </Layout>
  )
}

export default IndexPage
